import axios from '../../plugins/axios'
import { apiUrl, getAuth } from './utils'
import * as types from '../reducers/types'


export const setReadings = form => async dispatch => {

    const urlReadings = `${apiUrl}/readings`

    dispatch({ type: types.LOADING_MESSAGE, load: true })
    return await axios.post(urlReadings, form, getAuth())
        .then(() => {
            dispatch({ type: types.LOADING_MESSAGE, load: false })
            dispatch({
                type: types.NOTIFICATIONS,
                notification: { type: 'report', message: 'Lectura registrada con éxito.' }
            })
        })
}