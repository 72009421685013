export const metricas = {
    '0222': [
        { medicion: 10, caudal: 75, porcentaje: 21 },
        { medicion: 11, caudal: 87, porcentaje: 22 },
        { medicion: 12, caudal: 101, porcentaje: 25 },
        { medicion: 13, caudal: 115, porcentaje: 29 },
        { medicion: 14, caudal: 129, porcentaje: 33 },
        { medicion: 15, caudal: 145, porcentaje: 37 },
        { medicion: 16, caudal: 161, porcentaje: 41 },
        { medicion: 17, caudal: 177, porcentaje: 45 },
        { medicion: 18, caudal: 195, porcentaje: 49 },
        { medicion: 19, caudal: 212, porcentaje: 54 },
        { medicion: 20, caudal: 231, porcentaje: 58 },
        { medicion: 21, caudal: 250, porcentaje: 63 },
        { medicion: 22, caudal: 270, porcentaje: 68 },
        { medicion: 23, caudal: 290, porcentaje: 73 },
        { medicion: 24, caudal: 310, porcentaje: 78 },
        { medicion: 25, caudal: 332, porcentaje: 84 },
        { medicion: 26, caudal: 354, porcentaje: 89 },
        { medicion: 27, caudal: 376, porcentaje: 95 },
        { medicion: 27.9, caudal: 396, porcentaje: 100 },
    ],
    '0214': [
        { medicion: 15, caudal: 148, porcentaje: 31 },
        { medicion: 16, caudal: 157, porcentaje: 33 },
        { medicion: 17, caudal: 167, porcentaje: 35 },
        { medicion: 18, caudal: 176, porcentaje: 37 },
        { medicion: 19, caudal: 184, porcentaje: 39 },
        { medicion: 20, caudal: 193, porcentaje: 41 },
        { medicion: 21, caudal: 202, porcentaje: 43 },
        { medicion: 22, caudal: 211, porcentaje: 45 },
        { medicion: 23, caudal: 220, porcentaje: 47 },
        { medicion: 24, caudal: 229, porcentaje: 49 },
        { medicion: 25, caudal: 238, porcentaje: 50 },
        { medicion: 26, caudal: 246, porcentaje: 52 },
        { medicion: 27, caudal: 255, porcentaje: 54 },
        { medicion: 28, caudal: 264, porcentaje: 56 },
        { medicion: 29, caudal: 272, porcentaje: 58 },
        { medicion: 30, caudal: 281, porcentaje: 60 },
        { medicion: 31, caudal: 290, porcentaje: 61 },
        { medicion: 32, caudal: 298, porcentaje: 63 },
        { medicion: 33, caudal: 307, porcentaje: 65 },
        { medicion: 34, caudal: 315, porcentaje: 67 },
        { medicion: 35, caudal: 324, porcentaje: 69 },
        { medicion: 36, caudal: 332, porcentaje: 70 },
        { medicion: 37, caudal: 341, porcentaje: 72 },
        { medicion: 38, caudal: 349, porcentaje: 74 },
        { medicion: 39, caudal: 358, porcentaje: 76 },
        { medicion: 39.4, caudal: 361, porcentaje: 77 }
    ]
}

