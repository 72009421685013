import axios from '../../plugins/axios'
import moment from 'moment'
import * as type from '../reducers/types'
import { apiUrl, getAuth } from './utils'
import { metricas } from '../../components/tableLimnimeters/canal'

export const fetchDashboard = (code, user) => async dispatch => {
  dispatch({ type: type.LOADING, load: true })

  const url = code
    ? `${apiUrl}/dashboard-irrigators/${code}?_sort=createdAt:desc`
    : `${apiUrl}/dashboard-acmuser`


  /* Ultima fecha de actualización de las deudas */
  let last_update_fees = ''
  await axios
    .get(`${apiUrl}/file-update-controls/last-update-fees`, getAuth())
    .then(
      ({ data }) =>
        (last_update_fees = moment(data.createdAt).format('DD/MM/YYYY'))
    )

  /* Parametros adicionales que se les mande al store */
  let aditionalData = { last_update_fees }

  /* Si es un usuario de ACM */
  if (user && user.id) {
    /* Para el caso de los jefes de seccion. Lista de sus celadores */
    if (user.assigned_users && user.assigned_users.length)
      aditionalData = {
        ...aditionalData,
        total_assigned_watchmans: user.assigned_users.length
      }

    /* Fecha de ultima actualizacion de la lista de regantes */
    let last_update_irrigators = ''
    await axios
      .get(`${apiUrl}/file-update-controls/last-update-irrigator`, getAuth())
      .then(
        ({ data }) =>
          (last_update_irrigators = moment(data.createdAt).format('DD/MM/YYYY'))
      )

    let total_irrigators = 0
    await axios
      .get(`${apiUrl}/irrigators/count`, getAuth())
      .then(({ data }) => (total_irrigators = data))


    // Llamado a la API para la cosigna Actual
    const urlConsigna = `${apiUrl}/acm-infos`
    let consigna = {}
    await axios
      .get(urlConsigna, getAuth())
      .then(({ data }) => {
        consigna = data[0]
      }
      )

    // Arreglo con la Medición de Limnimetro

    const urlReadings = `${apiUrl}/readings`
    let readings_list = {}
    await axios
      .get(urlReadings, getAuth())
      .then(({ data }) => (readings_list = data[0]))


    // Obtener formula del Canal 

    if (readings_list) {
      // console.log("INFORMACION FORMULA :" + JSON.stringify(metricas[readings_list.limnimeter.channels.code].find(registro => registro.medicion === readings_list.measurement)))

      let dataCanal = metricas[readings_list.limnimeter.channels.code].find(registro => registro.medicion === readings_list.measurement)

      readings_list.consigna = parseFloat(consigna.acm_regulation.value.slice(0, -1))
      readings_list.porcentajeReal = dataCanal.porcentaje
      readings_list.caudal = dataCanal.caudal

      // let dateCreation = new Date(readings_list.createdAt
      
      const opciones = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      readings_list.dateCreation = new Date(readings_list.createdAt).toLocaleString('es-ES', opciones);
    }

    aditionalData = {
      ...aditionalData,
      total_irrigators,
      last_update_irrigators,
      readings_list
    }


  }

  return axios
    .get(url, getAuth())
    .then(({ data }) => {
      dispatch({
        type: type.FETCH_DASHBOARD,
        dash: { ...data, ...aditionalData }
      })
    })
    .catch(err => {
      dispatch({ type: type.ERROR, error: err })
    })
    .finally(() => {
      dispatch({ type: type.LOADING, load: false })
    })
}

// get channels list
export const getChannels = () => async dispatch => {
  const url = `${apiUrl}/channels?_sort=name:asc&_limit=200`

  return axios
    .get(url, getAuth())
    .then(({ data }) => {
      dispatch({
        type: type.GET_CHANNELS,
        channels: data
      })
    })
    .catch(err => dispatch({ type: type.ERROR, error: err }))
}

export const getReadings = () => async dispatch => {
  const urlReadings = `${apiUrl}/limnimeters`

  return axios.get(urlReadings, getAuth()).then(({ data }) => {
    dispatch({
      type: type.GET_READINGS,
      readings: data
    })
  })

}