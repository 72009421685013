/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Card from '../UI/card'
import * as types from '../../store/reducers/types'
import FormInput from '../UI/input'
import Button from '../UI/button'
import Logo from '../../assets/logo.png'
import { Wrapper, Error } from './styles'
import Modal from '../UI/modal'
import { loginHandler, userDataHandler } from '../../store/actions/login'
import { useDispatch, useSelector } from 'react-redux'

const Login = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const login = useSelector(({ login }) => login)
  const user = useSelector(({ user }) => user)
  const errorMsg = useSelector(({ errors }) => errors)
  const [form, setForm] = useState({
    identifier: '',
    password: ''
  })
  const [visiblePassword, viewPassword] = useState(false)
  const [validForm, setValid] = useState(false)
  const [error, setError] = useState(false)

  const handleLogin = event => {
    dispatch(loginHandler(form))

    event.preventDefault()
  }

  const isValid = () => {
    const valid =
      form.identifier.length >= 4 && form.password.length >= 4 ? false : true
    setValid(valid)
  }

  useEffect(() => {
    dispatch({ type: types.ERROR, error: {} })
    return () => {
      dispatch({ type: types.ERROR, error: {} })
    }
  }, [])

  useEffect(() => {
    isValid()
  }, [form])

  useEffect(() => {
    if (login && login.role) {
      dispatch(userDataHandler(login.role.name, login.code))
    } else {
      setError(true)
    }
  }, [login])

  useEffect(() => {
    /* Es nuevo */
    if (user && user.profile && !user.profile.app_setting.size) {
      history.push('/tour')
    } else if (user && (user.profile || user.association_area)) {
      /* El usuario es de acm o regante no nuevo */
      history.push('/panel-de-control')
    }
  }, [user])

  useEffect(() => {
    errorMsg && setError(errorMsg.message)
  }, [errorMsg])

  return (
    <>
      <Wrapper>
        <Card>
          <img src={Logo} alt='Canal Maule' />
          <form onSubmit={e => handleLogin(e)}>
            <FormInput
              className='field'
              label='Ingresa tu Código o Correo eletrónico'
            >
              <input
                type='text'
                placeholder='Código de Regante'
                onChange={e =>
                  setForm({ ...form, identifier: e.target.value.trim() })
                }
              />
            </FormInput>
            <FormInput className='field append' label='Ingresa tu Contraseña'>
              <span className='wrapper'>
                <input
                  type={visiblePassword ? 'text' : 'password'}
                  placeholder='Tu contraseña'
                  style={{ display: 'inline-block', width: 'calc(90% - 36px)' }}
                  onChange={e =>
                    setForm({ ...form, password: e.target.value.trim() })
                  }
                />
                <i
                  className={`fas ${
                    visiblePassword ? 'fa-eye-slash' : 'fa-eye'
                  }`}
                  onClick={() => viewPassword(!visiblePassword)}
                ></i>
              </span>
            </FormInput>
            <Button width='100%' type='submit' disabled={validForm}>
              Ingresar
            </Button>
          </form>
          <p>
            ¿Tienes problemas para iniciar sesión? <br />
            <a href='telf:+56993494792'>
              <strong>Contáctanos al (+569) 93494792</strong>
            </a>
          </p>
        </Card>
      </Wrapper>
      {error && (
        <Modal>
          <Error>
            <i className='fas fa-exclamation-triangle'></i>
            <p>{error}</p>
            <Button
              background='error'
              width='100%'
              onClick={() => {
                dispatch({ type: types.ERROR, error: null })
                setError(false)
              }}
            >
              Volver
            </Button>
          </Error>
        </Modal>
      )}
    </>
  )
}

export default Login
