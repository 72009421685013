import { useState } from 'react'
import Picker from 'react-mobile-picker'
import { PickerContent } from './styles'

function DatePickerWheel({ list, listTitle, onChangePickerIndex }) {
    const [pickerValue, setPickerValue] = useState([])

    const pickerChange = (e) => {
        setPickerValue(e)
        onChangePickerIndex(e)
    }

    return (
        <PickerContent>
            <div className='picker-header'>
                {listTitle.map(title => (
                    <div className='picker-header-title' key={title}>
                        <h5>{title}</h5>
                    </div>
                ))}
            </div>
            <Picker value={pickerValue} onChange={pickerChange}>
                {Object.keys(list).map(name => (
                    <Picker.Column key={name} name={name}>
                        {list[name].map(option => (
                            <Picker.Item key={option} value={option} >
                                <h5>
                                    {option}
                                </h5>
                            </Picker.Item>
                        ))}
                    </Picker.Column>
                ))}
            </Picker>
        </PickerContent >
    )
}

export default DatePickerWheel