import styled from 'styled-components'

export const Content = styled.div`
  position: relative;
  .stat-card {
    .error{
    border: 0.1rem solid red !important
    }
    .error-message{
    color: red
    }
    .deshabilitado {
      opacity: 0.5;
      pointer-events: none; /* Evita que se capturen eventos */
    }
    margin: 1rem auto;
      form {
        text-align: left;
        h1 {
          font-size: 18px;
          // color: ${({ theme }) => theme.secondary};
        }
        .form-limnimeter {
          margin: 1rem 0;
          .form-channel {
            select {
              width: 100%;
              height: auto;
              border: 1px solid #b5b5b5;
              border-radius: 7px;
              padding: 10px; 
              // color: #b5b5b5;
              margin: 0.5rem 0 0.2rem 0;
            }
          }
        }
        .form-measurement{
          display:flex;
          margin: 1rem 0;
          select {
            width: 100%;
            height: auto;
            border: 1px solid #b5b5b5;
            border-radius: 7px;
            padding: 10px; 
            // color: #b5b5b5;
            margin: 0.5rem 0 1rem 0;
            }
            .form-measurement-input{
            width:70%
            }
            .form-measurement-result{
            width:30%;
            justify-items:center;
            align-content: center;

            h3{
             background:#257dde;
             color:white;
             padding:0.5em;
            }
            }
          }
        .form-photo{
          margin: 1rem 0;
          .form-photo-add-button{
            width: fit-content;
            height:75px;
            border: 1px solid #a4a4a4;
            border-radius: 15px;
            padding:18px;
            margin: 1rem 1rem 3rem 1rem;
          }
          .form-photo-icon{ 
            i{
              font-size:80px;
            }
          }
          .form-photo-upload{
            display:flex;
            justify-content:center;
            align-items:center;
            background:#257dde;
            color:white;
            border-radius:100px;
            position:relative;
            left:95%;
            bottom:15%;
            width:50px;
            height:50px;
            i{
              font-size: 24px;
            }
          }
          .form-photo-image {
            .form-photo-image-delete{
              display:flex;
              justify-content:center;
              align-items:center;
              border: 1px solid gray;
              background:white;
              color:gray;
              border-radius:100px;
              position:relative;
              left:55%;
              top:18px;
              width:35px;
              height:35px;
              i{
                font-size: 24px;
              }
              }
              img{
                width: 60%;
                height: auto;
                object-fit: contain;
                -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.2);
                -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.2);
                box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.2);
              }    
          }
          button {
            background: transparent;
            border: 1px solid #b5b5b5;
            padding: 30px 10px;
            margin: 1rem 0;
            border-radius: 6px;
          }
        }
        .form-incidents{

          input[type="checkbox"] {
            display: none;
          }
          /* Estilizar el elemento personalizado */
          .checkmark {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-color: #fff;
            border: 2px solid #ccc;
            border-radius: 3px;
          }

          /* Estilizar el checkbox cuando está marcado */
          input[type="checkbox"]:checked + .checkmark {
            background-color: #4CAF50;
          }

          margin: 1rem 0;
          .form-incidents-flex{
            display:flex;
            flex-direction: column;
            gap: 1em;
            justify-content: space-evenly;
            margin: 0.5rem 0 1rem 0;
          }
        }
        .form-observation{
          margin: 1rem 0;
          input{
            margin: 0.5rem 0 1rem 0;
            width: 100%;
            min-height: 70px;
            border: 1px solid #b5b5b5;
            border-radius: 6px;
            padding:0;
          }
        }
        .form-button-add{
          display:flex;
          justify-content: center;
          button{
            background: #257dde;
            color: white;
            padding: 8px;
            border: 1px solid transparent;
          }
        }
      }
    }
  }
//   @media all and (min-width: 1280px) {
//     max-width: 1000px;
//     margin: 0 auto;
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     #card {
//       width: calc(100% - 3rem);
//     }
//   }
`
export const Prueba = styled.div`
/* Base for label styling */
	[type="checkbox"]:not(:checked),
	[type="checkbox"]:checked {
		position: absolute;
		left: 0;
		opacity: 0.01;
    
	}
	[type="checkbox"]:not(:checked) + label,
	[type="checkbox"]:checked + label {
		position: relative;
		padding-left: 1.8em;
		font-size: 1.05em;
		line-height: 1.7;
		cursor: pointer;
	}

	/* checkbox aspect */
	[type="checkbox"]:not(:checked) + label:before,
	[type="checkbox"]:checked + label:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 1.4em;
		height: 1.4em;
		border: 1px solid #aaa;
		background: #FFF;
		border-radius: 1em;
		box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(203, 34, 237, .2);
		-webkit-transition: all .275s;
				transition: all .275s;
	}

	/* checked mark aspect */
	[type="checkbox"]:not(:checked) + label:after,
	[type="checkbox"]:checked + label:after {
		// content: '✕';
    content: '\f058';
    font-family: 'fontAwesome';
		position: absolute;
		top: .525em;
		left: 0;
		font-size: 1.5em;
		color: #257dde;
		line-height: 0;
		-webkit-transition: all .2s;
				transition: all .2s;
	}

	/* checked mark aspect changes */
	[type="checkbox"]:not(:checked) + label:after {
		opacity: 0;
		-webkit-transform: scale(0) rotate(45deg);
				transform: scale(0) rotate(45deg);
	}

	[type="checkbox"]:checked + label:after {
		opacity: 1;
		-webkit-transform: scale(1) rotate(0);
				transform: scale(1) rotate(0);
	}

	/* Disabled checkbox */
	[type="checkbox"]:disabled:not(:checked) + label:before,
	[type="checkbox"]:disabled:checked + label:before {
		box-shadow: none;
		border-color: #bbb;
		background-color: #e9e9e9;
	}

	[type="checkbox"]:disabled:checked + label:after {
		color: #777;
	}

	[type="checkbox"]:disabled + label {
		color: #aaa;
	}

	/* Accessibility */
	[type="checkbox"]:checked:focus + label:before,
	[type="checkbox"]:not(:checked):focus + label:before {
		box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(203, 34, 237, .2);
	}


/*
 * Useless styles, just for demo design
 */
body {
  font-family: "Open Sans", "Segoe WP", "Segoe UI", Helvetica, Arial, sans-serif;
  text-align: center;
  color: #34495E;
    background: #FCFDFD;
}

a[href^="https://www.creativejuiz"] {
  color: #34495E;
  text-decoration: underline;
}

h1 {
  margin-top: 1em;
}

h2 {
  margin-top: 0;
  margin-bottom: 2em;
  color: #CB22ED;
  font-weight: normal;
}

form {
  width: 120px;
  margin: 0 auto 55px;
  text-align: left;
}

div p:first-child {
  font-weight: bold;
  font-size: 1.2em;
}

p { 
  color: #aaa;
}

p a {
  color: inherit;
}

p + p {
  margin-top: 3em;
}

form p {
  margin: 15px 0;
  color: #34495E;
}

a[href^="https://twitter.com"] {
  color: #1da1f2;
}

.btn.btn {
  display: inline-block;
  padding: 8px 24px;
  text-decoration: none;
  border-radius: 40px;
  background: #34495E;
  color: #F2F2F2;
  transition: all .4s;
}

.btn.btn:hover,
.btn.btn:focus {
  background: #000;
}
`

export const DetailData = styled.div`
  .detail-photo{
    position: relative;
    top: 35px;
    display: flex;
    justify-content: space-around;
    align-items:center;
  } 
  .detail-info{
    padding-top: 45px;
    .detail-info-content{
      display:flex;
      justify-content:space-around;
      align-items:center;
      padding: 15px 0;
      .detail-info-measurement{
        h1{
         font-size: 30px !important;
         color:#257dde;
        }
      }   
    }
    .detail-info-observation{
      text-align: left;
      padding: 1em;
      .detail-info-observation-label{
        margin: 5px 0;
        color:gray;
      }
      .detail-info-observation-data{
        margin-left: 1em
      }
    }     
    .detail-info-incidents{
      text-align: left;
      padding: 1em;
      .detail-info-incidents-label{
        margin: 5px 0;
        color:gray;
      }
      .detail-info-incidents-data{
        margin-left: 1em
      }
    }
    .detail-button{
      display:flex;
      justify-content: center;
      button{
        background: #257dde;
        color: white;
        padding: 8px;
        border: 1px solid transparent;
        }
      }      
  }
`