import UserWrapper, { ModalContent } from '../../hoc/userWrapper'
import Card from '../../UI/card'
import Button from '../../UI/button'
import { Content, DetailData, Prueba } from './styles'
import { metricas } from '../../tableLimnimeters/canal'
import { useDispatch, useSelector } from 'react-redux'
import { getLimnimeters } from '../../../store/actions/limnimeters'
import { setReadings } from '../../../store/actions/readings'
import React, { useEffect, useState, useRef } from 'react'
import { GhostLine } from '../../UI/ghostLoader'
import DatePickerWheel from '../../UI/picker/picker'
import Modal from '../../UI/modal'
import * as types from '../../../store/reducers/types'
import { useHistory } from 'react-router'

const CreateLimnimeter = () => {
    const dispatch = useDispatch()
    const listLimnimeter = useSelector(({ limnimeters }) => limnimeters)
    const notification = useSelector(({ notifications }) => notifications)
    const info = useSelector(({ information }) => information)
    const user = useSelector(({ user }) => user)
    const history = useHistory()
    const [listMeasurement, setListMeasurement] = useState(["Seleccione Canal"])
    const [channelError, setChannelError] = useState('');
    const [measurementError, setMeasurementError] = useState('');
    const [photoError, setPhotoError] = useState('');
    const [observationError, setObservationError] = useState('');
    const [activeForm, setActiveForm] = useState(true)
    const [detailData, setDetailData] = useState({})
    const [formData, setFormData] = useState({
        channel: "",
        measurement: 0,
        incidents: [
            {
                id: 1,
                name: "Lamas",
                state: false
            },
            {
                id: 2,
                name: "Ramas",
                state: false
            },
            {
                id: 3,
                name: "Hojas",
                state: false
            },
            {
                id: 4,
                name: "Intrusismo",
                state: false
            },
        ],
        observation: "",
        acm_regulation: 0
    });

    const parametersList = {
        centimeters: listMeasurement
    };
    const titleList = ['Centímetros']


    const handleFormSubmit = (event) => {

        event.preventDefault()
        let error = 0;
        if (!formData.channel) {
            setChannelError('* Debes seleccionar un canal');
            error += 1
        }
        if (!(listMeasurement.includes(formData.measurement))) {
            setMeasurementError('* Debes agregar una lectura')
            error += 1
        }
        if (!formData.observation) {
            setObservationError('* Debes agregar una observación');
            error += 1
        }
        let toSend = {
            ...formData,
            incidents: formData.incidents.filter(incident => incident.state === true),
            acm_regulation: info.infoCards,
            user_id: user.id
        }

        console.log(toSend)
        if (toSend.file) {
            delete toSend.file
            let form = new FormData()
            form.append('files.photo', formData.file, formData.file.name)
            form.append('data', JSON.stringify(toSend))
            toSend = form
        } else {
            error += 1
            setPhotoError('* Debes agregar una foto')
        }
        if (error === 0) {
            // Agrega consigna al Form
            setFormData({
                ...formData,
                acm_regulation: info.infoCards
            });
            dispatch(setReadings(toSend))
        }
    }

    /* Manejo de archivos */
    const hiddenFileInput = useRef(null)
    const handleFileClick = () => {
        hiddenFileInput.current.click()
    }

    const handleImageChange = (event) => {
        let reader = new FileReader()
        let file = event.target.files[0]
        reader.onloadend = () => {
            setFormData({ ...formData, file })
            const imagePreview = document.getElementById('imagePreview');
            imagePreview.src = reader.result;
        }
        reader.readAsDataURL(file)
        setPhotoError('')
    };

    const handleInputChange = (e) => {


        const { name, value, checked } = e.target;
        if (name === 'channel') {
            setListMeasurement(metricas[value]?.map((item) => item.medicion) ?? ["Seleccione Canal"])
        }
        if (name === 'incidents') {
            setFormData((prevState) => ({
                ...prevState,
                incidents: prevState.incidents.map((incident) => {
                    if (incident.name === value) {
                        return { ...incident, state: checked };
                    }
                    return incident;
                })
            }));
        } else {
            // Manejar otros campos del formulario
            setFormData({
                ...formData,
                [name]: value
            });
            name === 'channel' ? setChannelError('') : setObservationError('');

        }
    };
    const changePickerIndex = (e) => {
        setFormData({
            ...formData,
            measurement: e.centimeters
        })
        setMeasurementError('');
    }


    const handleModalAction = () => {
        setActiveForm(false)
        dispatch({ type: types.NOTIFICATIONS, notification: false })

        // Función para manejar la Data de la vista Detalles

        let data = {}
        let reader = new FileReader()
        let file = formData.file
        reader.onloadend = () => {
            const imagePreview2 = document.getElementById('imagePreview2');
            imagePreview2.src = reader.result;
        }
        reader.readAsDataURL(file)
        data.file = file
        data.channel = listLimnimeter.find(x => x.code === formData.channel)?.name ?? 'Canal no encontrado'
        data.consigna = formData.acm_regulation
        data.consignaReal = metricas[formData.channel].find(registro => registro.medicion === formData.measurement).porcentaje
        data.caudal = metricas[formData.channel].find(registro => registro.medicion === formData.measurement).caudal
        data.measurement = formData.measurement
        data.incidents = formData.incidents.filter(incident => incident.state === true)
        data.observation = formData.observation

        setDetailData(data)
    }

    useEffect(() => {
        dispatch(getLimnimeters())
    }, [dispatch])

    return (
        <UserWrapper pathName='Limnimetro'>
            <Content>
                {listLimnimeter.length > 0 ? (
                    <Card className='stat-card'>
                        {activeForm ? (
                            <form onSubmit={handleFormSubmit}>
                                <div >
                                    <h3>Registrar una nueva lectura de Limnímetro</h3>
                                </div>
                                <div className='form-limnimeter'>
                                    <div className='form-channel'>
                                        <h5>Seleccionar Canal</h5>
                                        <select name="channel" className={channelError ? 'error' : ''}
                                            onChange={handleInputChange} value={formData.channel}>
                                            <option value="">Selecciona un canal</option>
                                            {listLimnimeter.map(channel => (
                                                <option key={channel.code} value={channel.code}>{channel.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    {channelError && <p className="error-message">{channelError}</p>}
                                    <div className='form-measurement'>
                                        <div className='form-measurement-input'>
                                            <h5>Ingresar Lectura</h5>
                                            <DatePickerWheel list={parametersList} listTitle={titleList} onChangePickerIndex={changePickerIndex} />
                                        </div>
                                        <div className='form-measurement-result'>
                                            <h5>LECTURA</h5>
                                            <h3>{formData.measurement}</h3>
                                        </div>
                                    </div>
                                    {measurementError && <p className="error-message">{measurementError}</p>}
                                    <div className='form-photo'>
                                        <h5>Seleccionar Foto</h5>
                                        {formData.file ? (
                                            <div className='form-photo-image'>
                                                <div className='form-photo-image-delete'
                                                    onClick={() => {
                                                        const newForm = { ...formData }
                                                        delete newForm.file
                                                        setFormData(newForm)
                                                    }}>
                                                    <i className="fa fa-times" aria-hidden="true"></i>
                                                </div>
                                                <img id="imagePreview" src="" alt="Preview" />
                                            </div>
                                        ) : (
                                            <div>
                                                <div className='form-photo-add-button'>
                                                    <div className='form-photo-icon'>
                                                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                                                    </div>
                                                    <div className='form-photo-upload' onClick={() => handleFileClick()}>
                                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <input
                                                    type="file"
                                                    accept='image/*'
                                                    onChange={handleImageChange}
                                                    style={{ display: 'none' }}
                                                    ref={hiddenFileInput}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {photoError && <p className="error-message">{photoError}</p>}
                                    <div className='form-incidents'>
                                        <h5>Seleccionar Incidencias</h5>

                                        <div className='form-incidents-flex'>
                                            {formData.incidents.map((incident, index) => (
                                                <div key={index}>
                                                    <Prueba>
                                                        <div>
                                                            <p>
                                                                <input type="checkbox"
                                                                    id={incident.name}
                                                                    name="incidents"
                                                                    value={incident.name}
                                                                    checked={incident.state}
                                                                    onChange={handleInputChange} />
                                                                <label htmlFor={incident.name}>{incident.name}</label>
                                                            </p>
                                                        </div>
                                                    </Prueba>


                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                    <div className='form-observation'>
                                        <h5>Ingresar Observaciones</h5>
                                        <input name="observation" type="text" placeholder='Describa brevemente la lectura' onChange={handleInputChange} className={observationError ? 'error' : ''} />
                                        {observationError && <p className="error-message">{observationError}</p>}
                                    </div>
                                    <div className='form-button-add'>
                                        <button type='submit'>Ingresar Nueva Lectura</button>
                                    </div>

                                </div>
                            </form>

                        ) : (

                            <DetailData>
                                <h3 onClick={() => {
                                    handleModalAction()
                                }}>Vista detallada de la Lectura Registrada</h3>

                                <div className='detail-content'>
                                    <div className='detail-photo'>
                                        <div >
                                            <h5>Consigna</h5>
                                            <h2>{detailData.consigna}%</h2>
                                        </div>
                                        <div>
                                            <img id="imagePreview2" src="" alt="Preview" width="40%" />
                                        </div>
                                        <div >
                                            <h5>Consigna Real</h5>
                                            <h2>{detailData.consignaReal}%</h2>
                                        </div>
                                    </div>
                                    <div className='detail-info'>
                                        <div className='detail-info-channel'>
                                            <h2>Limnímetro {detailData.channel}</h2>
                                        </div>
                                        <div className='detail-info-content'>
                                            <div className='detail-info-measurement'>
                                                <h1>{detailData.measurement}cm</h1>
                                            </div>
                                            <div className='detail-info-caudal'>
                                                <h1>{detailData.caudal} L/S</h1>
                                            </div>
                                        </div>
                                        <div className='detail-info-observation'>
                                            <div className='detail-info-observation-label'>
                                                <h5>Observación</h5>
                                            </div>
                                            <div className='detail-info-observation-data'>
                                                <h4>{detailData.observation}</h4>
                                            </div>
                                        </div>
                                        <div className='detail-info-incidents'>
                                            <div className='detail-info-incidents-label'>
                                                <h5>Incidencias</h5>
                                            </div>
                                            {detailData.incidents?.map(incident => (
                                                <div className='detail-info-incidents-data' key={incident.id}>
                                                    <h4>*{incident.name}</h4>
                                                </div>
                                            )
                                            )}
                                        </div>
                                        <div className='detail-button' onClick={() => {
                                            history.push('/panel-de-control')
                                        }}>
                                            <button>Volver al Inicio</button>
                                        </div>
                                    </div>
                                </div>
                            </DetailData>
                        )}
                        {notification && notification.hasOwnProperty('message') && (
                            <Modal>
                                <ModalContent type='success'>
                                    <i className='fas fa-check'></i>
                                    <p>{notification.message}</p>
                                    <Button
                                        background='primary'
                                        width='100%'
                                        onClick={() => {
                                            handleModalAction()
                                        }}
                                    >
                                        Ver Detalle
                                    </Button>
                                </ModalContent>
                            </Modal>
                        )}
                    </Card>

                ) : (
                    <Card className='stat-card'>
                        <h2>No tienes Limnímetros Asignados</h2>
                        <GhostLine />
                        <GhostLine width='60%' />
                        <GhostLine width='40%' />
                    </Card>)
                }
            </Content>
        </UserWrapper>
    )
}

export default CreateLimnimeter