import * as types from './types'

export const readings = (dash = {}, action) => {
    switch (action.type) {
        case types.GET_READINGS:
            return action.readings
        default:
            return dash
    }
}
