import styled from 'styled-components'

export const Dash = styled.div`
  position: relative;
  .stat-card {
    margin: 1rem auto;
    section {
      text-align: left;
      h1 {
        font-size: 20px;
        color: ${({ theme }) => theme.primary};
      }
    }
    footer {
      text-align: left;
    }
    &.visits {
      section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        span {
          text-align: center;
          h1 {
            font-size: ${({ theme }) => theme.hSize};
          }
          &:first-child {
            border-right: 1px solid #a5a5a5;
          }
        }
      }
    }
    &.limnimeters {
      .title-limnimeters {
        text-align: left;
        opacity:0.7;
        margin-bottom: 0.5rem;
      }
      .assignment {  
        display:flex; 
        justify-content: center;
        padding:3em
      }
      .assignment-real {
        display: grid;
        grid-template-columns: 1fr 1fr;
        span{
          text-align:center;
          padding: 1em;
          &:first-child {
          border-right: 1px solid #a5a5a5;
          }
        }
      }
      .alert{
        border: 1px solid;
        padding: 10px;
        margin-top: 10px;
        .alert-header{
          display: flex;
          gap: 10px;
          // border 1px solid;
        }
        .alert-content{
        margin: 10px 0;
        padding-bottom: 10px;
        border-bottom: 1px solid #a5a5a5;
          // border: 1px solid; 
        }  
      } 
      .footer{
        // border: 1px solid;
      }    
    }
  }
  @media all and (min-width: 1280px) {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    #card {
      width: calc(100% - 3rem);
    }
  }
`
