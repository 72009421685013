import React, { useState, useEffect } from 'react'
import Header from '../../UI/header'
import Modal from '../../UI/modal'
import Button from '../../UI/button'
import styled from 'styled-components'
import { userDataHandler } from '../../../store/actions/login'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import * as type from '../../../store/reducers/types'
import { WatchmanInfo } from './styles'
import Avatar from '../../UI/avatar'
import Toggler from '../../UI/toggler'
import { socket } from '../utils'

const menuItems = [
  { name: 'Inicio', path: '/panel-de-control' },
  { name: 'información del canal', path: '/informacion' },
  { name: 'solicitudes', path: '/solicitudes' },
  { name: 'visitas', path: '/visitas' },
  { name: 'deudas', path: '/deudas' },
  { name: 'perfil', path: '/perfil' },
  { name: 'ajustes', path: '/opciones' },
  { name: 'cerrar sesión', path: '/cerrar-sesion' }
]

const adminMenu = [
  { name: 'Inicio', path: '/panel-de-control' },
  { name: 'Regantes', path: '/regantes' },
  { name: 'Solicitudes', path: '/solicitudes' },
  { name: 'Reportes de Visitas', path: '/visitas' },
  { name: 'Registrar Lecturas Limnimetros', path: '/limnimetros' },
  { name: 'información de los canales', path: '/canales' },
  { name: 'Notificaciones', path: '/notificaciones' },
  { name: 'ajustes', path: '/opciones' },
  { name: 'cerrar sesión', path: '/cerrar-sesion' }
]

/* Retornar el menu segun el tipo de usuario */
const selectMenu = type => {
  let menu = menuItems // Menu por defecto

  if (type !== 'irrigator') {
    menu = adminMenu

    /* Se oculta la opcion de notificaciones del celador y jefe de sección */
    if (type === 'watchman' || type === 'sectionm')
      menu = adminMenu.filter(item => item.path !== '/notificaciones')
  }

  return menu
}

export const ModalContent = styled.div`
  h2 {
    margin-bottom: 12px;
  }

  i {
    padding: 12px;
    background: ${({ theme, type }) =>
    type === 'error' ? theme.error : theme.primary};
    border-radius: 50%;
    font-size: 24px;
    color: white;
  }

  p {
    margin: 1rem auto;
    font-weight: 900;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`
const userDataPrev = JSON.parse(localStorage.getItem('userActive'))

const checkSession = fnClose => {
  socket.on('sesion:close', () => fnClose(true))
}

const UserLayout = props => {
  const { children, pathName } = props

  const history = useHistory()
  const dispatch = useDispatch()
  const errorMsg = useSelector(({ errors }) => errors)
  const notificationMsg = useSelector(({ notifications }) => notifications)
  const userData = useSelector(({ user }) => user)
  const codeActive = useSelector(({ codeActive }) => codeActive)

  const [error, setError] = useState()
  const [notification, setNotification] = useState()
  const [watchman, showWatchman] = useState()
  const [userCodes, setCodes] = useState()
  const [close, closeSession] = useState()

  useEffect(() => {
    /* Si refresca el navegador y no se encuentra localStorage se devuelve a inicio, sino se cargan los datos */
    if (userDataPrev) {
      /* Registra los datos del localstorage de la sesion */
      dispatch({
        type: type.LOGIN_FORM,
        form: userDataPrev
      })

      /* Debe estar aca, para que se ejecute una sola vez, ya que userDataHandler modifica a userData*/
      dispatch(userDataHandler(userDataPrev?.role?.type, userDataPrev.code))
    }
  }, [dispatch])

  useEffect(() => {
    !userData.id && !userDataPrev && history.push('/inicio')

    userData.code &&
      !codeActive &&
      dispatch({ type: type.SET_CODE, code: userData.code })

    !userCodes &&
      userData.my_other_codes &&
      userData.my_other_codes.length > 1 &&
      setCodes(userData.my_other_codes)
  }, [dispatch, history, userData, codeActive, userCodes])

  useEffect(() => {
    /* Si se inicia sesion en otro dispositivo, cierra la sesion */
    if (userData.id)
      setTimeout(() => {
        checkSession(closeSession)
      }, 1000)
  }, [userData])

  useEffect(() => {
    errorMsg?.hasOwnProperty('message') && setError(errorMsg)
  }, [errorMsg])

  useEffect(() => {
    notificationMsg?.hasOwnProperty('message') &&
      setNotification(notificationMsg)

  }, [notificationMsg])

  return (
    <>
      {userData.id && (
        <>
          <Header
            title={pathName ? pathName : 'Canal Maule'}
            menuItems={selectMenu(userData?.role?.name)}
            user={userData}
            menu
            back
            onClick={() => showWatchman(true)}
          />
          <section>{children}</section>
          {!pathName && userCodes && userCodes.length >= 1 && (
            <Toggler items={userData.my_other_codes} activeCode={codeActive} />
          )}
          {notification &&
            notification.hasOwnProperty('message') &&
            notification?.type !== 'report' && (
              <Modal>
                <ModalContent type='success'>
                  <i className='fas fa-exclamation-triangle'></i>
                  <p>{notification.message}</p>
                  <Button
                    background='primary'
                    width='100%'
                    onClick={() => {
                      setNotification(false)
                      dispatch({ type: type.NOTIFICATIONS, notification: '' })
                      notification.type !== 'location' &&
                        history.push('/panel-de-control')
                    }}
                  >
                    Volver
                  </Button>
                </ModalContent>
              </Modal>
            )}
          {error && error.hasOwnProperty('message') && (
            <Modal>
              <ModalContent type='error'>
                <i className='fas fa-exclamation-triangle'></i>
                <p>{error.message}</p>
                <Button
                  background='error'
                  width='100%'
                  onClick={() => {
                    setError(false)
                    dispatch({ type: type.ERROR, error: '' })
                  }}
                >
                  Volver
                </Button>
              </ModalContent>
            </Modal>
          )}
          {close && (
            <Modal>
              <ModalContent type='error'>
                <i className='fas fa-exclamation-triangle'></i>
                <p>Iniciaste sessión en otro dispositivo</p>
                <Button
                  background='error'
                  width='100%'
                  onClick={() => {
                    history.push('/cerrar-sesion')
                  }}
                >
                  Cerrar
                </Button>
              </ModalContent>
            </Modal>
          )}
          {watchman && userData.watchman && (
            <Modal>
              <h1>Conoce a tu celador</h1>
              <WatchmanInfo>
                <Avatar
                  width='80px'
                  height='80px'
                  className='avatar'
                  image={userData.watchman.picture}
                  altTxt={userData.watchman.name}
                />
                <div className='info'>
                  <p>
                    <strong>Nombre: </strong>
                    {userData.watchman.name}
                  </p>
                  <p>
                    <strong>Teléfono: </strong>
                    {userData.watchman.telephone || 'No disponible aún'}
                  </p>
                  <div className='actions'>
                    <Button
                      background='base'
                      onClick={() => showWatchman(false)}
                    >
                      cerrar
                    </Button>
                    <Button
                      disabled={userData.watchman.telephone ? '' : 'disabled'}
                    >
                      <a
                        href={
                          userData.watchman.telephone
                            ? `tel:${userData.watchman.telephone}`
                            : '#'
                        }
                      >
                        <i className='fas fa-phone' /> Llamar
                      </a>
                    </Button>
                  </div>
                </div>
              </WatchmanInfo>
            </Modal>
          )}
        </>
      )}
    </>
  )
}

export default UserLayout
