import axios from '../../plugins/axios'
import * as type from '../reducers/types'
import { apiUrl, getAuth } from './utils'

export const getLimnimeters = () => async dispatch => {
    const urlLimnimeters = `${apiUrl}/limnimeters`

    // Llamado a la API para la cosigna Actual
    const urlConsigna = `${apiUrl}/acm-infos`
    let consigna = {}
    await axios
        .get(urlConsigna, getAuth())
        .then(({ data }) => {
            consigna = data[0]
        }
        )

    consigna = parseFloat(consigna.acm_regulation.value.replace("%", ""))
    dispatch({
        type:type.GET_INFOCARDS,
        infoCards: consigna
    })

    return axios.get(urlLimnimeters, getAuth()).then(({ data }) => {
        // Creamos un Set para almacenar los códigos de canal únicos
        const canalesUnicos = new Set();

        // Iteramos sobre cada limnimetro
        const canales = data.map(limnimetro => {
            // Obtenemos el código del canal
            const codigoCanal = limnimetro.channels.code;

            // Si el código no está en el Set, lo agregamos y retornamos el canal
            if (!canalesUnicos.has(codigoCanal)) {
                canalesUnicos.add(codigoCanal);
                return limnimetro.channels;
            } else {
                return null
            }
        })
        dispatch({
            type: type.GET_LIMNIMETERS,
            dash: canales.filter(canal => canal !== null)
        })
    })

}