import styled from 'styled-components'

export const PickerContent = styled.div`
  .picker-header{
    display:flex;
    justify-content: space-around;
    color: gray;
    padding: 1em 0 0 0;
    h5{
    font-size: 10px;
    }
  }
  .picker-content{
      color:red;
  }
  @media all and (min-width: 1280px) {
    margin: 0 auto;
    max-width: 1000px;
  }
`