import * as types from './types'

export const limnimeters = (dash = {}, action) => {
  switch (action.type) {
    case types.GET_LIMNIMETERS:
      return action.dash
    default:
      return dash
  }
}
